/* Estilos generales */
.img {
  background-position: center;
  background-size: cover;
  border-radius: 50%; /* Hace que la imagen sea un círculo */
  overflow: hidden; /* Asegura que no haya desbordes */
  width: 80vw; /* Ajuste responsivo */
  height: 80vw;
  max-width: 400px;
  max-height: 400px;
}

/* Contenedor principal */
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  padding-top: 50px;
  margin-top: 0;
}

/* Título */
.about h1 {
  font-family: "Lovers Quarrel", cursive;
  font-size: 42px;
  font-weight: 400;
  color: black;
  text-align: center;
  margin-bottom: 20px;
}

.about h2{
  font-weight: 400;
  font-family: "'Lovers Quarrel', cursive";
  font-size: "4rem";
  font-weight: 400;
  color: "#00faf1";
}

/* Texto */
.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  text-align: justify;
  line-height: 1.6;
  font-size: 16px;
  color: #333;
  max-width: 90%;
}

.text h2 {
  font-size: 20px;
  color: #45A29E;
  margin: 15px 0;
  text-align: center;
  font-weight: 700;
}

.text ul {
  list-style-type: none;
  padding-left: 0;
  margin: 10px 0;
}

/* Ítems de lista con estrellitas */
.text li {
  margin-bottom: 10px;
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-left: 30px; /* Espacio para la estrella */
  line-height: 1.4;
}

.text li::before {
  content: "★";
  position: absolute;
  left: 0;
  color: #45A29E;
  font-size: 20px;
  top: -0.1em; /* 🔹 Asegura que la estrella esté alineada con la primera línea */
}

/* Botón de CV */
.cv-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.btn {
  text-decoration: none;
  padding: 10px 20px;
  background-color: #45A29E;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #397F7B;
}

/* Estilos para pantallas más grandes */
@media (min-width: 520px) {
  .text {
    padding: 20px;
    max-width: 600px;
  }

  .about {
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }

  .img {
    width: 300px;
    height: 300px;
  }
}

/* Estilos para pantallas grandes */
@media (min-width: 800px) {
  .img {
    width: 400px;
    height: 400px;
  }

  .text {
    max-width: 700px;
  }
}

/* Ajustes para pantallas móviles */
@media (max-width: 520px) {
  .about {
    flex-direction: column;
    align-items: center;
  }

  .img {
    width: 70vw;
    height: 70vw;
    border-radius: 50%;
    margin-bottom: 30px;
  }

  h1 {
    font-size: 36px;
    margin-bottom: 10px;
  }

  .text {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
