.contact {
  width: 100%;
  min-height: 100%;
  height: 400px;
  background: url("../../components/img/cyrwheelduoentera.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 25%;
  opacity: 0.9;
}

.contactSection {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bodycontact {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.contactSection h1 {
  font-size: 45px;
  padding: 25px;
}

.info {
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  font-size: 15px;
  gap: 20px;
}

.mail,
.whatsapp {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

/* Unified Link Styles */
.mail-link,
.whatsapp-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff; /* Default color */
  font-size: 18px; /* Unified font size */
  transition: color 0.3s ease, transform 0.3s ease;
  font-weight: 500;
}
/* Mail Icon Hover Effect */
.mail:hover .mail-icon {
  color: #4AB3F4; /* Cambia a azul celeste */
  transform: scale(1.1); /* Efecto de agrandamiento sutil */
}

.whatsapp-number{
  font-size: 20px;
}

.whatsapp-link:hover .whatsapp-icon {
  color: #25D366; /* WhatsApp hover color */
  transform: scale(1.1); /* Subtle zoom */
}

.mail-link:hover .mail-icon {
  transform: scale(1.1);
}

/* Icon Styles */
.whatsapp-icon,
.mail-icon {
  color: #fff; /* Default icon color */
  transition: color 0.3s ease, transform 0.3s ease;
}

.whatsapp-icon{
  margin-right: 15px;
}

/* Mobile Styles */
@media (max-width: 720px) {
  .networks ul{
    margin-top:40px ;
  }
  .whatsapp-link,
  .mail-link {
    font-size: 16px;
  }

  .whatsapp-icon,
  .mail-icon {
    transform: none; /* Disable transform on mobile */
  }
}

@media (min-width: 1080px) {
  .contact {
    height: 660px;
    background-attachment: fixed;
  }
}

/* Networks Section - No changes */
.networks {
  display: flex;
}
.networks ul {
  display: flex;
  position: absolute;
  top: 50%;
  left: 88%;
  transform: translate(-50%, -50%);
}

.networks ul li {
  list-style: none;
}

.networks ul li a {
  width: 70px;
  height: 70px;
  background-color: #fff;
  text-align: center;
  line-height: 77px;
  font-size: 35px;
  margin: 0 10px;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 3px solid #fff;
  z-index: 1;
}

.networks ul li a .icon {
  position: relative;
  color: #262626;
  transition: 0.5s;
  z-index: 3;
}

#tiktok:hover {
  fill: #fff;
  transform: rotateY(360deg);
  transition: 0.05s;
  z-index: 3;
}

.networks ul li a:hover .icon {
  color: #fff;
  transform: rotateY(360deg);
}

.networks ul li a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f00;
  transition: 0.5s;
  z-index: 2;
}

.networks ul li a:hover:before {
  top: 0;
}

.networks ul li:nth-child(1) a:before {
  background: #0000;
}

.networks ul li:nth-child(2) a:before {
  background: radial-gradient(
    circle at 33% 100%,
    #fed373 4%,
    #f15245 30%,
    #d92e7f 62%,
    #9b36b7 85%,
    #515ecf
  );
}

.networks ul li:nth-child(3) a:before {
  background: #ff0000;
}

/* Mobile Optimization: Disable transitions and show icons normally */
@media (max-width: 720px) {

  .networks ul {
    top: 230px;
    left: 50%;
  }

  .networks ul li a {
    width: 50px;
    height: 50px;
    line-height: 50px; /* Adjusted for better mobile view */
    font-size: 30px; /* Adjusted font size */
  }

  .networks ul li a:hover .icon {
    transform: none; /* Disable rotation on hover */
    color: #262626;  /* Keep the default color */
  }

  .networks ul li a:hover .tiktok-icon {
    fill: #262626 !important; /* Color personalizado solo para TikTok */
  }



  .networks ul li a:before {
    display: none; /* Hide the background effect */
  }

  .info {
    font-size: 20px; /* Adjusted font size */
    background-color: rgba(27, 27, 27, 0.5);
    width: 100%;
    padding-bottom: 15px;
    margin-left: 15px;
  }
}

@media (min-width: 720px) {
  .networks ul {
    top: 50%;
    left: 85%;
  }

  .networks ul li a {
    width: 60px;
    height: 60px;
    line-height: 68px;
    font-size: 35px;
  }

  .info {
    font-size: 25px;    
  }
}

@media (min-width: 1080px) {
  .contact {
    width: 100%;
    height: 660px;
    background-attachment: fixed;
  }

  .networks {
    display: flex;
  }

  .networks ul li a:hover .icon {
    color: #fff;
    transform: rotateY(360deg);
  }

  .networks ul li a:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f00;
    transition: 0.5s;
    z-index: 2;
  }

  .networks ul li a:hover:before {
    top: 0;
  }

  .networks ul li:nth-child(1) a:before {
    background: black;
  }

  .networks ul li:nth-child(2) a:before {
    background: radial-gradient(
      circle at 33% 100%,
      #fed373 4%,
      #f15245 30%,
      #d92e7f 62%,
      #9b36b7 85%,
      #515ecf
    );
  }

  .networks ul li:nth-child(3) a:before {
    background: #ff0000;
  }
}

@media (min-width: 2500px) {
  .contact {
    background-attachment: fixed;
  }

  #tiktok:hover {
    fill: #1D1D1D;
    transform: rotateY(360deg);
    transition: 0.05s;
    z-index: 3;
  }
}