.footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    color: #33dbd2;
    background-color: #1F1F1F;
}

.networks{
    margin-right: 10px;
}

@media (min-width: 100px) {
  .footer p {
    font-size: 10px;
  }
}

@media (min-width: 520px) {
    .footer p {
      font-size: 13px;
    }
  }