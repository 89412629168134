/* Contenedor principal */
.player-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #000; /* Fondo mientras carga el video */
  padding-top: 56.25%; /* Relación de aspecto 16:9 */
}

/* ReactPlayer ocupa todo el contenedor */
.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Contenedor de ondas */
.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

/* Ajustes en pantallas grandes */
@media (min-width: 1025px) {
  .wave .animated-wave:first-child {
    bottom: -1px;
  }
}

/* Ajustes en pantallas medias (iPads) */
@media (min-width: 768px) and (max-width: 1024px) {
  .player-wrapper {
    padding-top: 56.25%; /* Mantiene la proporción del video */
  }

  .wave {
    bottom: 0;
  }
}

/* Ajustes en pantallas pequeñas (iPad mini y menores) */
@media (max-width: 767px) {
  .player-wrapper {
    margin-top: 40px;
    padding-top: 68.25%; /* Mantiene la proporción del video */
  }

  .react-player {
    background: #000; /* Usa un fondo negro en lugar de cargar todo el video inicialmente */
  }

  .wave {
    display: none; /* Oculta las ondas para reducir carga en móviles */
  }
}
