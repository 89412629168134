* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
}

a{
  color: inherit;
  text-decoration: none;
  display: inline-block;
}

.body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #fff;
}

.carousel{
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #45A29E;
  padding: 20px;
}

.photos{
  width: 100%;
  margin: 30px 0 30px 0;
}




@keyframes wave {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-position-x: 0px;
  }
}

